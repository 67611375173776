import React from 'react'
import classes from './Answer.module.css'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'

const RadioBtn = withStyles({
  root: {
    color: 'default',
    '&$checked': {
      color: '#604f93'
    },
    '& svg:first-child': {
      transform: 'scale(1)'
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />)

export default props => {
  let cl = [classes.Answer]

  if (props.isAnswered) {
    if (props.displayCorrectAnswer) {
      if (props.selectedAnswer === props.id) {
        if (props.selectedAnswer === props.correct) cl.push(classes.Correct)
        else cl.push(classes.InCorrect)
      } else if (props.correct === props.id) {
        cl.push(classes.Correct)
      }
    }
  }

  return (
    <label htmlFor={props.id}>
      <div className={cl.join(' ')}>
        <RadioBtn
          checked={props.selectedAnswer === props.id}
          onChange={props.selected}
          value={props.id}
          inputProps={{ 'aria-label': props.id }}
          id={props.id}
        />
        <div className={classes.Text}>
          <span>{props.text}</span>
        </div>
      </div>
    </label>
  )
}
