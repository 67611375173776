import React from 'react'
import classes from './Explanation.module.css'
import CorrectIcon from '../../../images/icons/check_circle.svg'
import IncorrectIcon from '../../../images/icons/cancel.svg'

export default props => (
  <div
    className={[
      classes.Explanation,
      props.isCorrect ? classes.Correct : classes.InCorrect
    ].join(' ')}
  >
    {props.isCorrect ? (
      <img src={CorrectIcon} className={classes.Icon} alt="correct icon" />
    ) : (
      <img src={IncorrectIcon} className={classes.Icon} alt="incorrect icon" />
    )}
    <div>
      <span className={classes.Title}>
        {props.isCorrect ? 'Great job!' : 'Incorrect answer.'}
      </span>
      <p className={classes.Text}>{props.text}</p>
    </div>
  </div>
)
