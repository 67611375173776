import React, { Component } from 'react'
import classes from './Question.module.css'
import Examinator from '../Examinator/Examinator'
import Field from './Field/Field'
import Body from './Body/Body'
import Answer from './Answer/Answer'
import Button from './Button/Button'
import Explanation from './Explanation/Explanation'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index'
import { navigate } from 'gatsby'
import QuestionsData from '../../../data/questions/questionsShuffled.json'
import { shuffleArray } from '../../utils/shuffle/shuffle'

class Question extends Component {
  state = {
    isAnswered: false,
    answers: this.props.data.answers,
    selectedAnswer: false,
    correctAnswer: '_' + this.props.data.correct_answer,
    isCorrect: false,
    nextUnansweredQuestion: null
  }

  componentDidMount() {
    if (this.props.isActiveExam) {
      const notFieldKeyValue = answer =>
        answer[Object.keys(answer).filter(key => key !== 'field')[0]]
      const notFieldKey = answer =>
        Object.keys(answer).filter(key => key !== 'field')[0]

      const notAnsweredFromStatistics = this.props.statistics.flatMap(exam =>
        exam.result
          .filter(answer => notFieldKeyValue(answer) === false)
          .map(answer => notFieldKey(answer))
      )

      const answeredFromStatistics = this.props.statistics.flatMap(exam =>
        exam.result
          .filter(answer => notFieldKeyValue(answer) === true)
          .map(answer => notFieldKey(answer))
      )

      const allQuestionUrls = Object.values(QuestionsData)
        .map(question => question.url)
        .filter(url => !answeredFromStatistics.includes(url))

      const examQuestions = this.props.examQuestions.map(answer =>
        notFieldKey(answer)
      )

      const availableQuestions = Array.from(
        new Set(notAnsweredFromStatistics.concat(allQuestionUrls))
      )
        .filter(url => url !== this.props.data.url)
        .filter(url => !examQuestions.includes(url))

      const nextUnansweredQuestion = shuffleArray(availableQuestions)[0]

      if (nextUnansweredQuestion) this.setState({ nextUnansweredQuestion })
    }
  }

  handleAnswerSelected = event => {
    if (!this.state.isAnswered) {
      this.setState({
        selectedAnswer: event.target.value
      })
    }
  }

  handleButtonClick = () => {
    if (this.state.selectedAnswer) {
      this.setState(
        {
          isAnswered: true,
          isCorrect: this.state.selectedAnswer === this.state.correctAnswer
        },
        () => {
          if (this.props.isActiveExam) {
            this.props.onQuestionAnswer(
              this.props.data.field,
              this.props.data.url,
              this.state.isCorrect
            )

            if (!this.props.displayCorrectAnswer)
              navigate(
                `/${
                  this.state.nextUnansweredQuestion
                    ? this.state.nextUnansweredQuestion
                    : this.props.data.nextQuestion
                }`
              )
          }

          if (
            !this.props.paidAccessUntil ||
            this.props.paidAccessUntil < Date.now()
          ) {
            if (this.props.freeQuestions === 1 && this.props.isAuthenticated) {
              this.props.onUserUsedAllFreeQuestions(
                this.props.userId,
                this.props.token
              )
            }

            this.props.onUserUsedFreeQuestion()
          }
        }
      )
    }
  }

  render() {
    const displayCorrectAnswer = this.props.isActiveExam
      ? this.props.displayCorrectAnswer
        ? true
        : false
      : true

    let answers = []

    for (let answer in this.state.answers) {
      answers.push(
        <Answer
          key={answer}
          id={answer}
          text={this.state.answers[answer]}
          selected={this.handleAnswerSelected}
          selectedAnswer={this.state.selectedAnswer}
          isAnswered={this.state.isAnswered}
          correct={this.state.correctAnswer}
          displayCorrectAnswer={displayCorrectAnswer}
        />
      )
    }

    let tooltip = null
    let link = null
    if (!this.props.paidAccessUntil) {
      if (this.props.freeQuestions <= 0) {
        tooltip = 'Sign in to continue'
        link = 'pmp-training-sign-in'
      }
    } else if (this.props.paidAccessUntil < Date.now()) {
      if (this.props.freeQuestions <= 0) {
        tooltip = 'Replenish paid access to continue'
        link = 'pmp-preparation-profile'
      }
    }

    if (this.props.isActiveExam) {
      if (!this.state.selectedAnswer) {
        tooltip = 'You cannot skip questions on Mock Exam'
      }
    }

    let explanation = this.state.isAnswered ? (
      <Explanation
        text={this.props.data.explanation}
        isCorrect={this.state.isCorrect}
      />
    ) : null

    if (this.props.isActiveExam) {
      if (!this.props.displayCorrectAnswer) explanation = null
    }

    return (
      <div className={classes.Container}>
        <Examinator />
        {this.props.isActiveExam ? (
          <br />
        ) : (
          <Field text={this.props.data.field} />
        )}
        {explanation}
        <Body text={this.props.data.body} />
        <div>{answers}</div>
        <Button
          nextQuestion={
            this.props.lokedField === this.props.data.field
              ? this.props.data.nextLockedQuestion
              : this.state.nextUnansweredQuestion ||
                this.props.data.nextQuestion
          }
          isAnswerSelected={this.state.selectedAnswer !== false}
          isAnswered={this.state.isAnswered}
          click={this.handleButtonClick}
          tooltip={tooltip}
          link={link}
          displayCorrectAnswer={displayCorrectAnswer}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isActiveExam: state.exam.exam !== null,
    isAuthenticated: state.auth.token !== null,
    lokedField: state.field.locked,
    freeQuestions: state.user.freeQuestions,
    paidAccessUntil: state.user.paidAccessUntil,
    userId: state.user.id,
    token: state.auth.token,
    displayCorrectAnswer: state.exam.displayCorrectAnswer,
    statistics: state.statistics.exams,
    examQuestions: state.exam.result
  }
}

const mapActionToProps = dispatch => {
  return {
    onQuestionAnswer: (field, questionURL, isCorrect) =>
      dispatch(actions.questionAnswer(field, questionURL, isCorrect)),
    onUserUsedFreeQuestion: () => dispatch(actions.userUsedFreeQuestion()),
    onUserUsedAllFreeQuestions: (userId, token) =>
      dispatch(actions.userUsedAllFreeQuestions(userId, token))
  }
}

export default connect(mapStateToProps, mapActionToProps)(Question)
