export const shuffleArray = sourceArray => {
  for (let i = 0; i < sourceArray.length - 1; i++) {
    const j = i + Math.floor(Math.random() * (sourceArray.length - i))

    const temp = sourceArray[j]
    sourceArray[j] = sourceArray[i]
    sourceArray[i] = temp
  }

  return sourceArray
}

export const shuffleObject = sourceObj => {
  let arr = []

  // Convert obj to array
  for (let k in sourceObj) arr.push({ [k]: sourceObj[k] })

  arr = shuffleArray(arr)

  let shuffledObj = {}

  // Convert array to obj
  arr.map(a => (shuffledObj[Object.keys(a)[0]] = Object.values(a)[0]))

  return shuffledObj
}
