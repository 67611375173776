import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../hoc/Layout/Layout'
import SEO from '../components/seo'
import * as shuffle from '../utils/shuffle/index'

import QuestionComponent from '../components/Question/Question'

const Question = ({ data }) => {
  const question = data.question
  let tempAnswers = {}

  for (let key in data.question.answers) {
    if (data.question.answers[key] === null) continue

    tempAnswers[key] = data.question.answers[key]
  }

  question.answers = shuffle.shuffleObject(tempAnswers)

  const keywords = question.keywords.split(', ')

  const keyword = keywords[0]
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

  const description = `Exam Questions to get PMP Certification. ${
    question.body.split('. ')[0]
  }...`

  let title = question.id % 2 === 0 ? 'PMP Preparation' : 'PMP Training'
  title = title + ` | ${keyword} | ErudiCAT`

  return (
    <Layout>
      <SEO title={title} keywords={keywords} description={description} />
      <QuestionComponent data={data.question} />
    </Layout>
  )
}

export default Question

export const query = graphql`
  query($slug: String!) {
    question(fields: { slug: { eq: $slug } }) {
      id
      url
      body
      answers {
        _0A
        _0B
        _0C
        _0D
        _1A
        _1B
        _1C
        _1D
        _2A
        _2B
        _2C
        _2D
        _3A
        _3B
        _3C
        _3D
      }
      correct_answer
      field
      explanation
      keywords
      nextQuestion
      nextLockedQuestion
    }
  }
`
