import React from 'react'
import { Link } from 'gatsby'
import Button from '@material-ui/core/Button'
import Tooltip from '../../UI/Tooltip/Tooltip'

export default props => {
  let btn = (
    <Button variant="contained" onClick={props.click} style={style}>
      Answer
    </Button>
  )

  if (!props.isAnswerSelected || props.isAnswered) {
    btn = (
      <Link to={`/${props.nextQuestion}`}>
        <Button variant="contained" style={style}>
          Next
        </Button>
      </Link>
    )
  }

  if (props.tooltip && props.link) {
    btn = (
      <Link to={`/${props.link}`}>
        <Tooltip title={props.tooltip}>
          <Button variant="contained" style={style}>
            Next
          </Button>
        </Tooltip>
      </Link>
    )
  } else if (props.tooltip) {
    btn = (
      <Tooltip title={props.tooltip}>
        <Button variant="contained" style={style}>
          Next
        </Button>
      </Tooltip>
    )
  }

  return btn
}

const style = {
  backgroundColor: '#604f93',
  color: 'white',
  minWidth: '100px',
  marginTop: '25px',
  float: 'right'
}
